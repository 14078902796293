<template lang="pug">
    v-container.custom-container()
        v-layout(column align-center)
            v-layout(column xs12='' align-center).pt-2.ma-2.mt-3.text-center
                v-img( cover max-height='300px' max-width='600px' :src="image")
                .title.mt-2(style="color: rgb(76,76,76); font-size: 2.2rem !important; letter-spacing: -1.5px !important;") {{title}}
                v-divider.primary.mt-5( style="width: 100px;")

            v-layout(column style="font-size: 15px;" ).text-xs-justify.pa-3
                p.py-2.text-justify
                  h2.mr-2 Our Core Values
                p.py-2.text-justify
                  h3.mr-2 Integrity
                  |  To encourage honesty and decency in our community
                p.py-2.text-justify
                  h3.mr-2 Evidence
                  | To provide proof of what really occurred at the scene
                p.py-2.text-justify
                  h3.mr-2 Gain
                  |  To reward those involved
                p.py-2.text-justify
                  h3.mr-2 Hope
                  | Encourage the wrongly accused that things may work out
                p.py-2.text-justify
                  h3.mr-2 Golden Rule
                  | Treat others the way you want to be treated

</template>

<script>
  import image from '@/assets/images/static/values.jpg'
  export default {
    data () {
      return {
        title: 'Our Values',
        image: image,
      }
    },

    computed: {

    },

  }
</script>

<style lang='scss' scoped>

</style>
